<template>
  <div>
    <h3>
      Park Map
      <hr />
    </h3>

    <div class="row">
      <div class="col-md-6 col-sm-12">
        <label for="selectedLocationId">Park</label>
        <select
          class="form-control form-control-lg"
          id="selectedLocationId"
          v-model="selectedLocationId"
          @change="refreshSpotsAndPois"
          v-if="locations"
        >
          <option value="">Select Park...</option>
          <option
            v-for="location in locations"
            :key="location.id"
            :value="location.id"
            >{{ location.longName }}</option
          >
        </select>
        <span
          v-else
          class="spinner-border spinner-border-sm mx-3"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    </div>
    <br />
    <div class="card" v-if="selectedLocationId">
      <div class="card-header">
        <div class="card-title mb-0">Park Spots and Points of Interest</div>
      </div>
      <div class="card-body" v-if="spots.length > 0 && !loading">
        <p>
          Drag spots and points of interest to reposition on the map, then click
          'Save'.
        </p>
        <AdminSpotMap
          :searchResults="spots"
          :locationLatitude="selectedLocation.mapLatitude"
          :locationLongitude="selectedLocation.mapLongitude"
          :locationZoomLevel="selectedLocation.mapZoomLevel"
          :pois="pois"
          @spotsUpdated="spotsUpdated"
          @poisUpdated="poisUpdated"
          @zoomUpdated="zoomUpdated"
          @coordinatesUpdated="coordinatesUpdated"
        />
      </div>
      <div class="card-body text-center" v-if="loading">
        <div class="my-3">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </div>
    </div>
    <div>
      <p class="error" v-if="dataUpdated">
        You have unsaved changes. Click 'Save' to save your changes.
      </p>
      <button
        type="button"
        class="btn btn-lg btn-primary mt-4 mr-2"
        @click="save"
        :disabled="!dataUpdated"
      >
        Save
      </button>
      <button class="btn btn-cancel mt-4 mr-2" type="button" @click="cancel">
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import AdminLocationService from "@/services/admin/AdminLocationService.js";
import LocationService from "@/services/LocationService.js";
import AdminSpotMap from "@/components/admin/AdminSpotMap.vue";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";

export default {
  name: "AdminSpotAndPoiMap",
  title: "Admin - Spot And Point of Interest Map",
  mixins: [checkPermissionMixin],
  components: {
    AdminSpotMap
  },
  data() {
    return {
      selectedLocationId: "",
      locations: null,
      loading: false,
      spots: [],
      pois: [],
      dataUpdated: false,
      updatedSpotPositions: [],
      updatedPoiPositions: []
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    selectedLocation() {
      if (!this.selectedLocationId) {
        return null;
      }
      return this.locations.find(l => l.id == this.selectedLocationId);
    }
  },
  methods: {
    async refreshSpotsAndPois() {
      this.loading = true;
      await this.loadSpots();
      await this.loadPois();
      this.dataUpdated = false;
      this.updatedPoiPositions = [];
      this.updatedSpotPositions = [];
      this.updatedZoomLevel = null;
      this.updatedLatitude = null;
      this.updatedLongitude = null;
      this.loading = false;
    },
    async getLocations() {
      const locationService = new AdminLocationService(this.tenantId, null);
      const response = await locationService.getLocations(this.tenantId);
      this.locations = response.data.filter(loc =>
        this.checkLocationPermission("ParkManagementParkMap", loc.id)
      );
      this.loadingParks = false;
    },
    async loadSpots() {
      const locationService = new LocationService(
        this.tenantId,
        this.selectedLocationId
      );
      const searchParams = {
        locationId: this.selectedLocationId
      };
      const response = await locationService.spotSearchForLocation(
        searchParams
      );
      this.spots = response.spots;
    },
    async loadPois() {
      const locationService = new LocationService(
        this.tenantId,
        this.selectedLocationId
      );
      const response = await locationService.getPointsOfInterestForLocation();
      this.pois = response.locationPointsOfInterest;
    },
    spotsUpdated(spots) {
      this.updatedSpotPositions = spots;
      this.dataUpdated = true;
    },
    poisUpdated(pois) {
      this.updatedPoiPositions = pois;
      this.dataUpdated = true;
    },
    zoomUpdated(zoomLevel) {
      this.updatedZoomLevel = zoomLevel;
      this.dataUpdated = true;
    },
    coordinatesUpdated(coordinates) {
      this.updatedLongitude = coordinates.lng;
      this.updatedLatitude = coordinates.lat;
      this.dataUpdated = true;
    },
    cancel() {
      this.refreshSpotsAndPois();
    },
    save() {
      const spotPositions = this.updatedSpotPositions.map(sp => {
        return {
          itemId: sp.id,
          latitude: sp.latitude,
          longitude: sp.longitude
        };
      });

      const poiPositions = this.updatedPoiPositions.map(pp => {
        return {
          itemId: pp.id,
          latitude: pp.latitude,
          longitude: pp.longitude
        };
      });

      const request = {
        spotPositions: spotPositions,
        pointsOfInterestPositions: poiPositions,
        locationZoomLevel: this.updatedZoomLevel,
        locationLatitude: this.updatedLatitude,
        locationLongitude: this.updatedLongitude
      };

      const locationService = new AdminLocationService(this.tenantId, null);
      locationService
        .updateMapItemsPosition(this.selectedLocationId, request)
        .then(response => {
          if (response.statusCode == "Success") {
            const selectedLocation = this.locations.find(
              l => l.id == this.selectedLocationId
            );
            selectedLocation.mapZoomLevel = this.updatedZoomLevel
              ? this.updatedZoomLevel
              : selectedLocation.mapZoomLevel;
            selectedLocation.mapLatitude = this.updatedLatitude
              ? this.updatedLatitude
              : selectedLocation.mapLatitude;
            selectedLocation.mapLongitude = this.updatedLongitude
              ? this.updatedLongitude
              : selectedLocation.mapLongitude;
            this.refreshSpotsAndPois();
          } else {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: response.messages[0],
              layer: "public"
            });
          }
        });
    }
  },
  created() {
    this.getLocations();
  }
};
</script>
